var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"home",attrs:{"element-loading-text":"数据加载中","element-loading-background":"rgba(0, 0, 0, 0.2)"}},[_c('div',{staticClass:"Login-header"},[_c('div',{staticClass:"wrap"},[_vm._m(0),_c('div',{staticClass:"nav"},[_c('div',{staticClass:"item",style:(_vm.productServiceTitleState == 0
                ? 'color:#2B67EE;font-weight: bold;'
                : ''),on:{"click":function($event){return _vm.Jump('/index')}}},[_vm._v(" 首页 "),_c('div',{staticClass:"title-content-underLine",style:(_vm.productServiceTitleState == 0 ? 'transform: scaleX(1);' : '')})]),_c('div',{staticClass:"item",style:(_vm.productServiceTitleState == 1
                ? 'color:#2B67EE;font-weight: bold;'
                : ''),on:{"click":function($event){return _vm.Jump('/CompetitionList')}}},[_vm._v(" 比赛列表 "),_c('div',{staticClass:"title-content-underLine",style:(_vm.productServiceTitleState == 1 ? 'transform: scaleX(1);' : '')})]),(false)?_c('div',{staticClass:"item",style:(_vm.productServiceTitleState == 10
                ? 'color:#2B67EE;font-weight: bold;'
                : ''),on:{"click":function($event){return _vm.Jump('/QA')}}},[_vm._v(" 问答 "),_c('div',{staticClass:"title-content-underLine",style:(_vm.productServiceTitleState == 10 ? 'transform: scaleX(1);' : '')})]):_vm._e()]),_c('div',{staticClass:"login"},[(_vm.ThisRouter != '/login')?[(_vm.iSLoginPage == 0)?_c('div',{staticClass:"home-button",on:{"click":function($event){return _vm.LoginPageJump('/login')}}},[_vm._v(" 登录 ")]):_vm._e()]:_vm._e(),(_vm.iSLoginPage == 1 && _vm.ManagementCenterState)?_c('div',{staticClass:"item",style:(_vm.productServiceTitleState == 2
                ? 'color:#2B67EE;font-weight: bold;'
                : ''),on:{"click":function($event){return _vm.Jump('/ManagementCenter')}}},[_vm._v(" 管理中心 "),_c('div',{staticClass:"title-content-underLine",style:(_vm.productServiceTitleState == 2 ? 'transform: scaleX(1);' : '')})]):_vm._e(),(_vm.iSLoginPage == 1)?_c('div',{staticClass:"item",style:(_vm.productServiceTitleState == 3
                ? 'color:#2B67EE;font-weight: bold;'
                : ''),on:{"click":function($event){return _vm.Jump('/PersonalInfo')}}},[_vm._v(" 个人中心 "),_c('div',{staticClass:"title-content-underLine",style:(_vm.productServiceTitleState == 3 ? 'transform: scaleX(1);' : '')})]):_vm._e()],2)])]),(_vm.ThisRouter == '/index')?_c('div',{staticClass:"banner"}):_vm._e(),_c('div',{staticClass:"appMainStyle"},[_c('app-main',{on:{"getTreeData":_vm.LodingData}})],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-bar"},[_c('div',{staticClass:"item",on:{"click":_vm.RegistrationAgreement}},[_vm._v(" 注册协议 ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"item",on:{"click":_vm.UseHelp}},[_vm._v(" 使用帮助 ")]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"item",on:{"click":_vm.trainingmanual}},[_vm._v(" 培训手册 ")]),_c('div',{staticClass:"line"})]),_vm._m(1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logo"},[_c('span',[_vm._v("Credit Lab")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"600px","margin":"0 auto","padding":"10px 0","display":"flex","align-items":"center","justify-content":"center","position":"absolute","left":"calc(50% - 300px)","bottom":"-5px"}},[_vm._v(" ICP证: "),_c('a',{staticStyle:{"line-height":"20px","height":"20px"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v(" 蜀ICP备2021022597号-5 ")]),_c('a',{staticStyle:{"display":"flex","text-decoration":"none","height":"20px","line-height":"20px","padding-left":"20px"},attrs:{"target":"_blank","href":"http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002004358"}},[_c('img',{attrs:{"src":require("../assets/imgs/9971da80bcc741a5c615f8c1837036d.png")}}),_c('p',{staticStyle:{"float":"left","height":"20px","line-height":"20px","margin":"0px 0px 0px 5px","color":"#939393"}},[_vm._v(" 川公网安备 51019002004358号 ")])])])}]

export { render, staticRenderFns }