<template>
  <div>
    <div id="H5app" v-if="this.isMobile">
      <router-view />
    </div>
    <div id="app" v-if="!this.isMobile">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      isMobile: true,
      screenWidth: null, //屏幕宽度
    };
  },
  watch: {
    screenWidth: function (val) {
		if(this.isMobile){
			if(val > 500){
				window.location.href = '/';
			}
		}else{
			if(val < 800){
				window.location.href = '/';
			}
		}
    },
  },
  mounted() {
    let that = this;
    window.addEventListener("resize", function () {
      that.screenWidth = document.body.offsetWidth;
    });
    if (this.isMobileFun()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  methods: {
    isMobileFun() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  created() {},
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
  background-color: #f5fafc;
  box-sizing: border-box;
}
#app {
  /* width: 1920px; */
  width: 100%;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 250, 252);
  /* box-sizing: border-box; */
}
/* ::-webkit-scrollbar {
  width: 3px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background-color: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: rgba(0,0,0,0.1);
} */
#H5app {
  width: 100vw;
  margin: 0 auto;
  font-size: 12px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245, 250, 252);
  box-sizing: border-box;
}
</style>
