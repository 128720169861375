import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout'

Vue.use(Router)

export const constantRoutes = [
         {
           path: "/redirect",
           component: Layout,
           hidden: true,
           children: [
             {
               path: "/redirect",
               component: (resolve) =>
                 require(["@/views/redirect.vue"], resolve),
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/index",
           children: [
             {
               path: "/index",
               component: (resolve) => require(["@/views/index.vue"], resolve),
               name: "首页",
               meta: {
                 title: "比赛列表",
                 icon: "dashboard",
                 noCache: false,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/CompetitionList",
           children: [
             {
               path: "/CompetitionList",
               component: (resolve) =>
                 require(["@/views/CompetitionList/index.vue"], resolve),
               name: "比赛列表",
               meta: {
                 title: "比赛列表",
                 icon: "dashboard",
                 noCache: false,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/CodeWritingResults",
           children: [
             {
               path: "/CodeWritingResults",
               component: (resolve) =>
                 require(["@/views/CodeWritingResults/index.vue"], resolve),
               name: "编写结果分析代码",
               meta: {
                 title: "编写结果分析代码",
                 icon: "dashboard",
                 noCache: false,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/SingleItem",
           children: [
             {
               path: "/SingleItem",
               component: (resolve) =>
                 require(["@/views/SingleItem/index.vue"], resolve),
               name: "单个比赛",
               meta: {
                 title: "单个比赛",
                 icon: "dashboard",
                 noCache: false,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/PersonalInfo",
           children: [
             {
               path: "/PersonalInfo",
               component: (resolve) =>
                 require(["@/views/PersonalInfo/index.vue"], resolve),
               name: "个人信息",
               meta: {
                 title: "个人信息",
                 icon: "dashboard",
                 noCache: true,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/QA",
           children: [
             {
               path: "/QA",
               component: (resolve) =>
                 require(["@/views/QA/index.vue"], resolve),
               name: "问答",
               meta: {
                 title: "问答",
                 icon: "dashboard",
                 noCache: false,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/ManagementCenter",
           children: [
             {
               path: "/ManagementCenter",
               component: (resolve) =>
                 require(["@/views/ManagementCenter/index.vue"], resolve),
               name: "管理中心",
               meta: {
                 title: "管理中心",
                 icon: "dashboard",
                 noCache: true,
                 affix: true,
               },
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/login",
           children: [
             {
               path: "/login",
               component: (resolve) => require(["@/views/login.vue"], resolve),
               name: "登录",
             },
           ],
         },
         {
           path: "",
           component: Layout,
           redirect: "/ForgetPassword",
           children: [
             {
               path: "/ForgetPassword",
               component: (resolve) => require(["@/views/ForgetPassword.vue"], resolve),
               name: "忘记密码",
             },
           ],
         },
         {
           path: "/404",
           component: (resolve) => require(["@/views/error/404.vue"], resolve),
           hidden: true,
         },
         {
           path: "/401",
           component: (resolve) => require(["@/views/error/401.vue"], resolve),
           hidden: true,
         },
       ];

export const H5constantRoutes = [
    {
        path: "/",
        // component: Layout,
        component: (resolve) => require(["@/views/H5index.vue"], resolve),
        name: "首页",
        meta: {
            title: "比赛列表",
            icon: "dashboard",
            noCache: false,
            affix: true,
        },
    },
    {
        path: "/CompetiH5",
        component: (resolve) =>
            require(["@/views/CompetitionListH5/index.vue"], resolve),
        name: "比赛列表",
        meta: {
            title: "比赛列表",
            icon: "dashboard",
            noCache: false,
            affix: true,
        },
    },
    {
        path: "/SingleItemH5",
        component: (resolve) =>
            require(["@/views/SingleItemH5/index.vue"], resolve),
        name: "比赛详情",
        meta: {
            title: "比赛详情",
            icon: "dashboard",
            noCache: false,
            affix: true,
        },
    },
    {
        path: "/agreementH5",
        component: (resolve) =>
            require(["@/views/agreementH5/index.vue"], resolve),
        name: "用户协议",
        meta: {
            title: "用户协议",
            icon: "dashboard",
            noCache: false,
            affix: true,
        },
    },
    {
        path: "/ManagementCenterH5",
        component: (resolve) =>
            require(["@/views/ManagementCenterH5/index.vue"], resolve),
        name: "管理中心",
        meta: {
            title: "管理中心",
            icon: "dashboard",
            noCache: true,
            affix: true,
        },
    },
    {
        path: "/PersonalInfoH5",
        component: (resolve) =>
            require(["@/views/PersonalInfoH5/index.vue"], resolve),
        name: "个人中心",
        meta: {
            title: "个人中心",
            icon: "dashboard",
            noCache: true,
            affix: true,
        },
    },
    {
        path: "/Info",
        component: (resolve) =>
            require(["@/views/PersonalInfoH5/Info.vue"], resolve),
        name: "修改个人信息",
        meta: {
            title: "修改个人信息",
            icon: "dashboard",
            noCache: true,
            affix: true,
        },
    },
    {
        path: "/MyGame",
        component: (resolve) =>
            require(["@/views/PersonalInfoH5/MyGame.vue"], resolve),
        name: "我的比赛信息",
        meta: {
            title: "我的比赛信息",
            icon: "dashboard",
            noCache: true,
            affix: true,
        },
    },
    {
        path: "/H5login",
        component: (resolve) => require(["@/views/H5login.vue"], resolve),
        name: "登录",
    },
    {
        path: "/404",
        component: (resolve) => require(["@/views/error/404.vue"], resolve),
        hidden: true,
    },
    {
        path: "/401",
        component: (resolve) => require(["@/views/error/401.vue"], resolve),
        hidden: true,
    },
];
export default new Router({
    // mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({ y: 0 }),
    routes: (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
        ? H5constantRoutes
        : constantRoutes,
});
